<template>
   <div id="body">
    <div class="content">
      <div class="teacherInfo">
        <div class="photos">
          <img src="../../assets/APP_logo.png" alt="" />
          <p>王老师</p>
          <p>海纳白川，有容乃大</p>
        </div>
        <div class="brief">
          <p><i class="el-icon-setting"></i>初中教师</p>
          <p>
            <i class="el-icon-setting">大连市第七十二中学-高一三班-班主任</i>
          </p>
          <p><i class="el-icon-setting">辽宁省大连市</i></p>
        </div>
      </div>
      <div class="fatherBox">
          <div class="headerBox">
        <p>历史记录</p>
      </div>
      <div class="headinfo">
          <p>任教时间：2015.02.09~2015.03.09</p>
        <div class="headinfo_title"><p>班级编号： 52137875  </p><p style="margin-left:30px">班级人数：50人</p></div>
      </div>
        <div id="main" style="width: 100%; height: 400px; background: white"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.drawChart()
  },
  methods: {
    drawChart () {
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: { trigger: 'axis' },
        legend: { data: ['左眼', '右眼', '双眼'] },
        xAxis: {
          data: ['MOn', 'Tue', 'Ser', 'Fir', 'Sun', 'Sta']
        },
        yAxis: {},
        series: [
          {
            name: '左眼',
            type: 'line',
            // data:this.year[this.nowYear]
            data: [5, 20, 36, 10, 10, 20]
          },
          {
            name: '右眼',
            type: 'line',
            // data:this.year[this.nowYear]
            data: [10, 30, 36, 20, 15, 21]
          },
          {
            name: '双眼',
            type: 'line',
            // data:this.year[this.nowYear]
            data: [9, 21, 25, 30, 11, 22]
          }
        ]
      }
      myChart.setOption(options)
    }
  }
}
</script>

<style lang="scss" scoped>
    #body {
  // height: 100%;
  overflow: hidden;
  // background: #f0f2f5;
}
.content {
  width: 98%;
  display: flex;
//   justify-content: space-evenly;
  margin: 0 auto;
  margin-top: 32px;
  .teacherInfo {
      width: 20%;
    //   width: 350px;
      height: 363px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 24px;
    justify-content: center;
    background: white;
    img {
      width: 106px;
      height: 106px;
      border-radius: 50%;
      background: yellowgreen;
    }
    .photos{
        p:nth-child(2){
            font-size: 20px;
            font-family: PingFangSC-Medium;
            color: rgba(0, 0, 0, 0.85);
        }
        p:nth-child(3){
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .brief{
        p{
            text-align: left;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
        }
    }
  }
}

.fatherBox {
  width: 75%;
  display: flex;
  background: white;
  margin: 0 auto;
  flex-direction: column;
//   justify-content: center;
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    border-bottom: 2px solid #e9e9e9;
    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }
  }
  .headinfo{
       display: flex;
       flex-direction: column;
        p{
            text-align: left;
            margin-left: 33px;
        }
        p:nth-child(1){
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: #8C8C8C;
        }
      .headinfo_title{
       display: flex;
       p{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
       }
      }
  }
}
</style>
